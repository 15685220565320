import { PageType, UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SlotType } from '../../containers/settings/settings.types'

export function checkRemoveBrandingEnabled(slots?: PageType['slots']): boolean {
  if (!slots) return false
  return slots?.some(
    slot => slot?.slotMeta?.type === SlotType.SETTINGS_REMOVE_FOOTER && slot?.data?.isEnabled
  )
}

export function checkCustomFooterEnabled(slots?: PageType['slots']): UnauthSlot {
  if (!slots) return undefined
  return slots?.find(slot => slot?.slotMeta?.type === SlotType.CUSTOM_FOOTER)
}

import { SVGProps, memo } from 'react'

function SaveSvg({
  stroke = 'currentcolor',
  fill = 'white',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" {...rest}>
      <path
        d="M16.3643 3.13601C19.8792 6.65101 19.8792 12.349 16.3643 15.864C12.8492 19.379 7.15125 19.379 3.63625 15.864C0.12125 12.349 0.12125 6.65101 3.63625 3.13601C7.15125 -0.378994 12.8492 -0.378994 16.3643 3.13601Z"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.0005 4.49988V11.4999V4.49988Z" fill="white" />
      <path
        d="M10.0005 4.49988V11.4999"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0253 9.47485L10.0003 11.4999L7.97534 9.47485"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.46436 13.0359C8.41736 14.9889 11.5824 14.9889 13.5354 13.0359H6.46436Z"
        fill="white"
      />
      <path
        d="M6.46436 13.0359C8.41736 14.9889 11.5824 14.9889 13.5354 13.0359"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Save = memo(SaveSvg)

import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, TextStyleProps } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { UrlObject } from 'url'
import Link from 'next/link'

interface LinkProps {
  as?: string
  target?: string
}

export interface NextLinkProps extends TextStyleProps, Omit<BoxProps, 'color'> {
  href: string | UrlObject
  linkProps?: LinkProps
  display?: string
}

//from flowcode
export const NextLinkWrap: FC<NextLinkProps> = ({
  children,
  href,
  display = 'block',
  linkProps,
  ...rest
}: NextLinkProps) => {
  return (
    // the passHref and legacyBehavior attributes appear like they are unneccessasry
    // but break visual styling if not applied here.
    <Link href={href} passHref legacyBehavior {...linkProps}>
      <Box display={display} {...rest}>
        {children}
      </Box>
    </Link>
  )
}

import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, theme as sharedTheme } from '@dtx-company/shared-components/src'
import { ContactCardStyleProps, ContactLinkItemType } from './types'
import { ContactIconComponent } from '../Contact/ContactIcon'
import { ContactInfoLinkCard } from './ContactInfoLinkCard'
import { FC, ReactElement } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SaveContactButton } from '@app/pre-build/src/components/OnboardingFlows/ContactSharing/SaveContactButton'
import { ShareContactButton } from '@app/pre-build/src/components/OnboardingFlows/ContactSharing/ShareContactButton'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { useContactInfoIconStyles } from './theme'
import { useContactInfoWidgetState } from './hooks'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useNewTabSlots } from '@app/page/src/hooks/useNewTabSlots'
import { useShareContactButtonState } from '@app/pre-build/src/components/OnboardingFlows/ContactSharing/ShareContactButton/hooks'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ContactInfoBubbleIcon: FC<
  ContactLinkItem & {
    preview?: boolean
    iconStyleProps: BoxProps
    linkId: string
    parentLinkId?: string | undefined
    isOutline: boolean
    size: number
    displayType: string
    dataTestid?: string
  }
> = ({
  prefix,
  type,
  value,
  linkId,
  size,
  parentLinkId,
  preview,
  onClick,
  isOutline,
  iconStyleProps,
  dataTestid
}) => {
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: false
  })

  const newTabSlot = useNewTabSlots()
  const openLinkInSameTab = newTabSlot?.data?.sameTab

  return (
    <Box
      filter={sharedTheme.shadows.drop}
      {...iconStyleProps}
      mx="4px"
      data-testid={dataTestid}
      borderRadius="50%"
      onClick={() => {
        trackLinkClick({ link_id: linkId, parent_link_id: parentLinkId })
        !preview && events.userClickedContactInfoIcon({ type: type ?? '', linkId })
      }}
    >
      {onClick ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
          onClick={onClick}
        >
          <ContactIconComponent
            isOutline={isOutline}
            type={type}
            size={size}
            color={iconStyleProps.color}
          />
        </Box>
      ) : (
        <StyledLink href={(prefix || '') + value} {...(!openLinkInSameTab && { target: '_blank' })}>
          <ContactIconComponent
            isOutline={isOutline}
            type={type}
            size={size}
            color={iconStyleProps.color}
          />
        </StyledLink>
      )}
    </Box>
  )
}

export const ShareBar: FC<{
  preview: boolean
  editor?: boolean
  contactLink?: LinkType | null
  cardStyleProps: ContactCardStyleProps
}> = ({ preview, editor, contactLink, cardStyleProps }) => {
  const { usersBrowserCanSharePage, onClickShareButton } = useShareContactButtonState({
    linkId: contactLink?.id ?? ''
  })
  return (
    <Box justifyContent={'center'}>
      <SaveContactButton
        cardStyleProps={cardStyleProps}
        editor={editor}
        preview={preview}
        linkId={contactLink?.id ?? ''}
      />
      {usersBrowserCanSharePage && (
        <>
          <Spacer mr="8px" />
          <ShareContactButton
            cardStyleProps={cardStyleProps}
            editor={editor}
            preview={preview}
            onClickShareButton={onClickShareButton}
          />
        </>
      )}
    </Box>
  )
}

interface ContactLinkItem {
  prefix?: string
  onClick?: () => void
  color?: string
  type?: ContactLinkItemType
  value?: string
}

// interface ContactWidgetItems {
//   [key: string]: ContactLinkItem
// }
type ContactWidgetItems = Record<ContactLinkItemType, ContactLinkItem>

const contactLinkItems: ContactWidgetItems = {
  email: { prefix: 'mailto:' },
  call: { prefix: 'tel:' },
  text: { prefix: 'sms:' },
  location: {},
  website: {}
}

export const Flowpage: FC<FlowpageProps> = (props: FlowpageProps) => {
  const { contactTypes, childLinksObject, setOpenLocationModal, isOutline, isLinkCardDisplayType } =
    useContactInfoWidgetState(props)

  const hasContactInfo =
    contactTypes.call?.length > 0 ||
    contactTypes.location?.length > 0 ||
    contactTypes.email?.length > 0 ||
    contactTypes.text?.length > 0 ||
    contactTypes.website?.length > 0
  const contactTypeMap = !props.preview ? childLinksObject : contactTypes
  const { iconStyleProps, cardStyleProps } = useContactInfoIconStyles({ link: props.link })

  const isAllowVisitorsDownloadingVCardEnabled = props.link.actionData?.card
  if (hasContactInfo && isLinkCardDisplayType)
    return (
      <ContactInfoLinkCard
        link={props.link}
        preview={props.preview}
        isEditLinkPreview={props.isEditLinkPreview}
      />
    )
  return (
    <>
      {hasContactInfo && (
        <Box width="100%" flexDirection="column">
          <Box justifyContent="center" flexWrap="wrap">
            {Object.entries(contactTypeMap).map(
              ([contactType, contactInfoValue]): ReactElement | undefined => {
                const processLinkValue = contactInfoValue as Link
                const parentLinkId = processLinkValue?.parentLinkId
                const childLinkId = !props.preview ? processLinkValue?.id : props.link.id
                const valueString = !props.preview
                  ? processLinkValue?.actionData?.value
                  : String(contactInfoValue)
                if (contactType === 'location' && contactInfoValue) {
                  return (
                    <ContactInfoBubbleIcon
                      key={'location'}
                      dataTestid={`${contactType}-icon`}
                      parentLinkId={parentLinkId ?? undefined}
                      isOutline={isOutline}
                      linkId={childLinkId}
                      preview={props.preview}
                      displayType={props.link.displayType || 'default'}
                      type={contactType}
                      size={contactTypes.iconSize || 24}
                      iconStyleProps={iconStyleProps}
                      onClick={() => {
                        setOpenLocationModal(true, valueString)
                      }}
                    />
                  )
                } else if (contactInfoValue && contactType in contactLinkItems) {
                  const item = indexInterfaceWithVar(contactLinkItems, contactType)
                  return (
                    <ContactInfoBubbleIcon
                      dataTestid={`${contactType}-icon`}
                      parentLinkId={parentLinkId ?? undefined}
                      key={contactType}
                      displayType={props.link.displayType || 'default'}
                      size={contactTypes.iconSize || 24}
                      prefix={item?.prefix}
                      type={contactType as ContactLinkItemType}
                      isOutline={isOutline}
                      linkId={childLinkId}
                      preview={props.preview}
                      iconStyleProps={iconStyleProps}
                      value={valueString ?? undefined}
                    />
                  )
                }
              }
            )}
          </Box>
          {isAllowVisitorsDownloadingVCardEnabled && (
            <>
              <Spacer mb="16px" />
              <ShareBar
                preview={props.preview ?? false}
                contactLink={props.link}
                cardStyleProps={cardStyleProps}
              />
            </>
          )}
        </Box>
      )}
    </>
  )
}

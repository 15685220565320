import { SVGProps, memo } from 'react'

function ShareSvg({
  stroke = 'currentcolor',
  color = 'currentcolor',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...rest}>
      <path
        d="M15.364 3.13604C18.8787 6.65077 18.8787 12.3493 15.364 15.864C11.8492 19.3787 6.15075 19.3787 2.63604 15.864C-0.878681 12.3492 -0.878681 6.65075 2.63604 3.13604C6.15077 -0.378681 11.8493 -0.378681 15.364 3.13604Z"
        fill="white"
      />
      <path
        d="M9.31401 6.95006C6.547 6.95006 4.25 9.02306 4.25 12.4711V13.1651C5.186 10.9011 7.13 10.0731 9.31401 10.0731V11.9591C9.31401 12.4921 9.94701 12.7721 10.342 12.4141L14.048 9.05406C14.324 8.80506 14.316 8.37006 14.033 8.13006L10.327 4.98406C9.92701 4.64506 9.31401 4.92806 9.31401 5.45206V6.95006Z"
        fill={color}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Share = memo(ShareSvg)

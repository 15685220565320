import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactCardStyleProps } from '@app/page/src/components/widgets/ContactInfo/types'
import { FC } from 'react'
import { NextLinkWrap } from '@app/page/src/components/util/NextLinkUtils'
import { Save } from '@dtx-company/shared-components/src/foundation/Icons/Actions/Save'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { getApiRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { theme as sharedTheme } from '@dtx-company/shared-components/src'
import { useFlowpageAnalyticsCollector } from '@app/page/src/hooks/useFlowpageAnalyticsCollector'

export const SaveContactButton: FC<{
  preview?: boolean
  editor?: boolean
  cardStyleProps: ContactCardStyleProps
  linkId?: string
}> = ({ preview = false, editor = false, cardStyleProps, linkId }) => {
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: preview,
    isEditMode: editor
  })

  return (
    <>
      {editor ? (
        <Box
          cursor="pointer"
          borderRadius="50px"
          padding="8px 12px 8px 8px"
          alignItems="center"
          minWidth="170px"
          filter={sharedTheme.shadows.drop}
          border={'1px solid'}
          {...cardStyleProps}
        >
          <Save stroke={cardStyleProps.stroke} />
          <Spacer mr="4px" />
          <Text
            variant="body/medium"
            fontSize={preview ? '14px' : '16px'}
            color={cardStyleProps.color}
            style={{ textDecoration: 'underline' }}
            fontWeight="bold"
          >
            Add to Contacts
          </Text>
        </Box>
      ) : (
        <NextLinkWrap
          cursor="pointer"
          borderRadius="50px"
          padding="8px 12px 8px 8px"
          alignItems="center"
          minWidth="170px"
          filter={sharedTheme.shadows.drop}
          border={'1px solid'}
          href={!preview ? `${getApiRootUrl()}/contact?linkId=${linkId}` : ''}
          onClick={() => {
            if (!preview) {
              trackLinkClick({ link_id: linkId })
            }
          }}
          {...cardStyleProps}
          display="flex"
        >
          <Save stroke={cardStyleProps.stroke} />
          <Spacer mr="4px" />
          <Text
            variant="body/medium"
            fontSize={preview ? '14px' : '16px'}
            color={cardStyleProps.color}
            style={{ textDecoration: 'underline' }}
            fontWeight="bold"
          >
            Add to Contacts
          </Text>
        </NextLinkWrap>
      )}
    </>
  )
}

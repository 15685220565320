import { InnerLinkCard, StyledLink } from '../../flowpage/FlowpageLinkCard'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getApiRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'

export interface ContactInfoLinkCardProps {
  link: LinkType
  preview?: boolean
  isEditLinkPreview?: boolean
}

/**
 * Renders the contact info link as a single Link card that downloads the vCard
 * on click
 *
 * Copied a lot the general functionality and structure from FlowpageLinkCard, instead of using
 * FlowpageLinkCard to add the  onClick handler for tracking
 * this link type specifically
 *
 * Introduced in sc-57631 (https://app.shortcut.com/flowcode/story/57631/additional-vcard-style-option)
 */
export const ContactInfoLinkCard = ({
  link,
  preview,
  isEditLinkPreview
}: ContactInfoLinkCardProps): JSX.Element => {
  // Override title and add actionData.link
  // Title is hardcoded for now. We may offer customizing the title as well as the icon in the future
  // contactInfo links don't have actionData.link, here we add it with the url to download
  // the vCard
  const contactInfoLinkWithOverrides: LinkType = {
    ...link,
    title: 'Save My Contact Details',
    actionData: { ...link.actionData, link: `${getApiRootUrl()}/contact?linkId=${link.id}` }
  }

  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(isEditLinkPreview)
  })

  return (
    <StyledLink
      id={`hp-flowpage-link_card-${link.id}`}
      href={contactInfoLinkWithOverrides.actionData?.link}
      rel="noopener"
      onClick={() => {
        if (!preview) {
          trackLinkClick({ link_id: link.id })
        }
      }}
      data-link_id={link.id}
      data-testid="flowpage-link"
    >
      <InnerLinkCard link={contactInfoLinkWithOverrides} />
    </StyledLink>
  )
}

import { ContactInfoDisplayTypes } from '../Contact/types'
import { ContactLinkItemType } from './types'
import { FlowpageProps } from '../types/FlowpageProps'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils/modal'

interface ContactInfoWidgetState {
  contactTypes: Record<string, any>
  childLinksObject: Record<ContactLinkItemType, LinkType>
  setOpenLocationModal: (open: boolean, location?: string) => void
  isOutline: boolean
  isLinkCardDisplayType: boolean
}
export function useContactInfoWidgetState(props: FlowpageProps): ContactInfoWidgetState {
  const contactTypes = props.link.actionData || {}
  const childLinks = props.link.childLinks || []
  const childLinksObject: Record<string, LinkType> = childLinks
    .filter(child => child?.actionData.value.length > 0)
    .reduce((obj, child) => Object.assign(obj, { [child?.provider ?? '']: child }), {})
  const { setOpen, setClose } = useModal(ModalTypes.LOCATION)
  const isLinkCardDisplayType = props.link.displayType === ContactInfoDisplayTypes.LINK_CARD

  const setOpenLocationModal = (open: boolean, location?: string): void => {
    if (open && location) {
      setOpen({ location })
      return
    }
    setClose()
  }

  return {
    contactTypes,
    childLinksObject,
    setOpenLocationModal,
    isOutline: contactTypes.iconFill === 'outline',
    isLinkCardDisplayType
  }
}

/* eslint-disable  */
import { FC } from 'react'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  Text,
  TruncateTextContainer,
  TruncateText,
  BodyText,
  GeneralText,
  DisplayText,
  AccordionText,
  BannerText,
  ButtonText,
  ChartText,
  CheckboxText,
  HeaderText,
  InputText,
  NavigationText,
  PillText,
  RadioText,
  TableText,
  TagText,
  ToastText,
  ToggleText,
  TextGradient
} from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { theme } from '@dtx-company/shared-components/src'
import styled from 'styled-components'
import Link from 'next/link'
import { MarketingPageRoutes, Routes } from '@dtx-company/true-common/src/constants/routes'
const FlowPageLogo = styled.img`
  width: 80px;
  height: 20px;
`

const PoweredByFlowPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  flex-direction: column;
`
const getFlowpageLogo = (color: string): string => {
  if (color === '#FFF') {
    return '/logos/longform/flowpage-xs-white.svg'
  }

  return '/logos/longform/flowpage-xs-black.svg'
}

const PoweredByFlowPage: FC<{ color: string }> = ({ color }) => (
  <PoweredByFlowPageWrapper>
    <Link href={MarketingPageRoutes.PAGE} target="_blank" rel="noopener noreferrer">
      <Box flexDirection="column" alignItems="center">
        <FlowPageLogo src={getFlowpageLogo(color)} alt="Powered by Flowpage" />
        <Text
          mb="2px"
          variant="general/captionAndLabel"
          color="flowpage.contrastText"
          textAlign="center"
          fontFamily={theme.fontFamily}
        >
          All your links in one place
        </Text>
      </Box>
    </Link>
  </PoweredByFlowPageWrapper>
)

export default PoweredByFlowPage

/* eslint-disable  */
import { FC } from 'react'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  Text,
  TruncateTextContainer,
  TruncateText,
  BodyText,
  GeneralText,
  DisplayText,
  AccordionText,
  BannerText,
  ButtonText,
  ChartText,
  CheckboxText,
  HeaderText,
  InputText,
  NavigationText,
  PillText,
  RadioText,
  TableText,
  TagText,
  ToastText,
  ToggleText,
  TextGradient
} from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { theme } from '@dtx-company/shared-components/src'
import styled from 'styled-components'
import Link from 'next/link'
import { MarketingPageRoutes } from '@dtx-company/true-common/src/constants/routes'

const FlowCodeLogo = styled.img`
  width: 80px;
  height: 20px;
`

const getFlowcocdeLogo = (color: string): string => {
  if (color === '#FFF') {
    return '/logos/longform/flowcode-xs-white.svg'
  }

  return '/logos/longform/flowcode-xs-black.svg'
}
const PoweredByFlowCode: FC<{ color: string }> = ({ color }) => (
  <Box width="180px" flexDirection="column" alignItems="center" justifyContent="center">
    <Link
      href={{
        pathname: MarketingPageRoutes.FREE_QR_CODE_GENERATOR,
        query: {
          utm_source: 'flowpage',
          utm_medium: 'footer',
          utm_campaign: 'footer_link'
        }
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box flexDirection="column" alignItems="center">
        <FlowCodeLogo src={getFlowcocdeLogo(color)} alt="Powered by Flowcode" />
        <Text
          fontFamily={theme.fontFamily}
          variant="general/captionAndLabel"
          color="flowpage.contrastText"
          textAlign="center"
        >
          Free QR code generator
        </Text>
      </Box>
    </Link>
  </Box>
)

export default PoweredByFlowCode

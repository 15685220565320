import {
  BorderProps,
  ColorProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  ZIndexProps
} from 'styled-system'
import { CombinedTextProps } from '../atoms'
import { SIZES } from '@dtx-company/true-common/src/constants/mediaQueries'

export type DrawerProps = ColorProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  ZIndexProps &
  BorderProps

export type ComponentProps = {
  children?: React.ReactNode
  open: boolean
  disableAutoClose?: boolean
  onClose: () => void
  delay?: number
  title?: React.ReactNode
  closeButtonId?: string
  closeButtonIcon?: string
  hideCloseButton?: boolean
  wrapperProps?: LayoutProps & BorderProps & ColorProps
  titleProps?: Omit<CombinedTextProps, 'children'>
  /** Whether modal will render thru a portal (default) or as descendant of it's parent (useful for event propagation) */
  usingPortal?: boolean
} & DrawerProps

export enum ModalVariants {
  SHEET = 'sheet',
  FULLPAGE = 'fullPage',
  DESKTOP = 'desktop'
}

export interface ModalProps extends ComponentProps {
  breakpoint?: SIZES
  variant: ModalVariants | [ModalVariants, ModalVariants]
}

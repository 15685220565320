import { BoxProps, theme as sharedTheme } from '@dtx-company/shared-components/src'
import { COLORS, HEX_COLOR_TO_NAME, LinkStyle, Theme } from '../../../constants/customizeTheme'
import { ContactCardStyleProps } from './types'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getCustomThemeValues } from '../../../utils/theme'
import { getLinkCardStyles } from '../../../components/flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { getValidatedActionData } from '../typeUtils'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'
const {
  secondary: { disabled },
  masterWeb: { white }
} = sharedTheme.colors

const getShareCardStyleProps = ({
  link,
  blackOnBlackBg,
  color
}: {
  blackOnBlackBg: boolean
  color: string
  link: LinkType | Partial<Link>
}): ContactCardStyleProps => {
  const ad = getValidatedActionData<'contactInfo'>(link?.actionData, 'iconFill')
  const isEnabled = Boolean(link?.actionData?.card)

  //circle
  if (link.displayType === 'widget')
    return {
      background: 'white',
      color: isEnabled ? color : disabled,
      stroke: isEnabled ? color : disabled
    }

  //fill & floating
  if (ad?.iconFill === 'fill') {
    return {
      background: isEnabled ? color : disabled,
      color: 'white',
      stroke: isEnabled ? color : disabled
    }
  }
  const floatingColor = blackOnBlackBg ? white : color
  //outline & floating
  return {
    background: 'transparent',
    color: isEnabled ? floatingColor : disabled,
    stroke: color
  }
}

const isBlackOnBlackBg = ({
  bgImage,
  color,
  bgColor,
  bgStyle
}: {
  bgImage: boolean
  color: string
  bgColor: string
  bgStyle?: string | null
}): boolean => {
  if (bgImage) return false
  if (
    color === bgColor &&
    (color === 'rgb(0, 0, 0)' ||
      color === HEX_COLOR_TO_NAME[COLORS.black] ||
      color === COLORS.black) &&
    (bgStyle === Theme.dark || bgStyle === Theme.color)
  )
    return true
  return false
}
const getContactIconStyleProps = ({
  displayType,
  blackOnBlackBg,
  color
}: {
  displayType?: string
  color: string
  blackOnBlackBg: boolean
}): Omit<BoxProps, 'children' | 'onClick'> => {
  if (displayType === 'widget') {
    return {
      bg: 'primary.white',
      color: color,
      border: `1px solid ${color}`,
      padding: '12px'
    }
  }
  return { color: blackOnBlackBg ? white : color }
}

export function useContactInfoIconStyles({
  link,
  inManager = false
}: {
  link?: LinkType | Partial<Link>
  inManager?: boolean
}): {
  iconStyleProps: Omit<BoxProps, 'children' | 'onClick'>
  cardStyleProps: ContactCardStyleProps
} {
  const { theme } = useFlowpageTheme()
  if (!link) {
    return {
      iconStyleProps: {
        color: disabled,
        background: disabled
      },
      cardStyleProps: {
        color: disabled,
        background: disabled,
        stroke: disabled
      }
    }
  }
  const linkTheme = link?.linkTheme
  const displayType = link?.displayType

  const themeValues = getCustomThemeValues(theme)
  const linkStyles = getLinkCardStyles({
    theme: { id: theme?.id || '', ...theme, linkStyle: LinkStyle.DEFAULT },
    linkTheme
  })
  const color = (
    linkTheme?.active && linkTheme?.linkColor
      ? linkStyles.background || ''
      : themeValues.primaryColor
  ) as string
  const blackOnBlackBg =
    isBlackOnBlackBg({
      bgImage: Boolean(theme?.backgroundImgUrl),
      color,
      bgColor: themeValues.primaryColor,
      bgStyle: theme?.style
    }) && !inManager

  const iconStyleProps = getContactIconStyleProps({ displayType, color, blackOnBlackBg })

  const cardStyleProps = getShareCardStyleProps({
    link,
    blackOnBlackBg,
    color
  })
  return { iconStyleProps, cardStyleProps }
}

import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import PoweredByFlowCode from './PoweredByFlowCode'
import PoweredByFlowPage from './PoweredByFlowPage'
import styled from 'styled-components'

interface PoweredByFooterProps {
  contrastText: string
  preview: boolean
  zIndex?: number
}
const VerticalDivider = styled(Box)`
  content: '';
  height: 2em;
  width: 1px;
  display: block;
`
export const PoweredByFooter: FC<PoweredByFooterProps> = ({ contrastText, preview, zIndex }) => {
  return (
    <Box
      opacity={1}
      zIndex={zIndex ?? 9}
      maxWidth={preview ? '240px' : '500px'}
      margin="16px auto"
      justifyContent="space-evenly"
      alignItems="start"
      className="powered-by-footer"
    >
      <PoweredByFlowPage color={contrastText} />
      <VerticalDivider
        margin={['4px 0px', '4px 0px', '0px 4px', '0px 4px']}
        bg="flowpage.contrastText"
      />
      <PoweredByFlowCode color={contrastText} />
    </Box>
  )
}

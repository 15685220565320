import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactCardStyleProps } from '@app/page/src/components/widgets/ContactInfo/types'
import { FC } from 'react'
import { Share } from '@dtx-company/shared-components/src/foundation/Icons/Actions/Share'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { theme as sharedTheme } from '@dtx-company/shared-components/src'

export const ShareContactButton: FC<{
  onClickShareButton: () => void
  preview?: boolean
  editor?: boolean
  cardStyleProps: ContactCardStyleProps
}> = ({ onClickShareButton, preview = false, editor = false, cardStyleProps }) => {
  return editor ? (
    <Box
      display="flex"
      borderRadius="50px"
      padding="8px 12px 8px 8px"
      alignItems="center"
      border={'1px solid'}
      cursor="pointer"
      filter={sharedTheme.shadows.drop}
      {...cardStyleProps}
    >
      <Share color={cardStyleProps.stroke} stroke={cardStyleProps.stroke} />
      <Spacer mr="4px" />
      <Text
        variant="body/medium"
        fontSize={preview ? '14px' : '16px'}
        color={cardStyleProps.color}
        style={{ textDecoration: 'underline', fontWeight: 'bold' }}
      >
        Share
      </Text>
    </Box>
  ) : (
    <Box
      display="flex"
      borderRadius="50px"
      padding="8px 12px 8px 8px"
      alignItems="center"
      border={'1px solid'}
      cursor="pointer"
      filter={sharedTheme.shadows.drop}
      onClick={onClickShareButton}
      {...cardStyleProps}
    >
      <Share color={cardStyleProps.stroke} stroke={cardStyleProps.stroke} />
      <Spacer mr="4px" />
      <Text
        variant="body/medium"
        fontSize={preview ? '14px' : '16px'}
        color={cardStyleProps.color}
        style={{ textDecoration: 'underline', fontWeight: 'bold' }}
      >
        Share
      </Text>
    </Box>
  )
}

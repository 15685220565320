import { Box } from '../../atoms/Box/index'
import { ComponentProps } from '../../../types/organisms/modal.types'
import { Icon } from '../../atoms/Icon/index'
import { MouseEvent, ReactPortal, useEffect, useRef, useState } from 'react'
import { Text } from '../../atoms/Text/index'
import { ZIndexProps, border, layout, overflow, zIndex } from 'styled-system'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import ReactDOM from 'react-dom'
import closeIcon from '../../../static/icons/close-dark.svg'
import styled from 'styled-components'

const StyledAside = styled.aside<{ delay: number; bg: string } & ZIndexProps>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: ${({ bg }: { bg: string }) => bg};
  transition: ${({ delay }: { delay: number }) => `${delay}ms background-color`};
  z-index: ${({ theme }) => theme.zIndex.aside};
  ${zIndex}
`
type PosType = {
  top: string
  left?: string
}
const SheetWrapper = styled(Box)`
  max-width: calc(100% - 72px);
  height: auto;
  justify-content: center;
  min-width: 400px;
  left: 50%;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex.modal};
  webkit-overflow-scrolling: touch;
  min-height: 400px;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 72px);
  transition: ${({ delay }: { delay: number }) => `${delay}ms top`};
  border-radius: ${({ theme }) => `${theme.radii[3]}px`};
  ${overflow}
  ${layout}
  ${border}
`
export const DesktopModal = ({
  children,
  open,
  onClose,
  delay = 250,
  title,
  disableAutoClose = false,
  zIndex,
  closeButtonId,
  hideCloseButton = false,
  overflow = 'auto',
  closeButtonIcon = closeIcon,
  wrapperProps,
  titleProps,
  usingPortal = true,
  ...rest
}: ComponentProps): ReactPortal | JSX.Element | null => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const [pos, setPos] = useState<PosType>({ top: '100%' })
  const [bg, setBg] = useState<string>('none')
  const [portalOpen, setPortalOpen] = useState<boolean>(open)
  const isMounted = useIsMounted()
  const onOutsideClick = (e: MouseEvent<HTMLElement>): void => {
    if (drawerRef.current?.contains(e?.target as Node) || disableAutoClose) return
    onClose()
  }

  useEffect(() => {
    let timeoutHandler: ReturnType<typeof setTimeout>
    if (open) {
      setPortalOpen(true)
      timeoutHandler = setTimeout(() => {
        setPos({ top: '50%' })
        setBg('rgba(0, 0, 0, 0.8)')
      }, 100)
    }
    if (!open && portalOpen) {
      setPos({ top: '100%' })
      setBg('none')
      timeoutHandler = setTimeout(() => {
        setPortalOpen(false)
      }, delay - 50)
    }
    return function cleanup() {
      clearTimeout(timeoutHandler)
    }
  }, [open, portalOpen, delay])

  const Content = (
    <StyledAside zIndex={zIndex} delay={delay} bg={bg} onClick={onOutsideClick}>
      <SheetWrapper
        delay={delay}
        position="absolute"
        {...pos}
        overflow={overflow}
        {...wrapperProps}
      >
        <Box
          ref={drawerRef}
          borderRadius="12px"
          position="relative"
          backgroundColor="secondary.backgroundDark"
          width="100%"
          {...rest}
        >
          {!hideCloseButton && (
            <Box position="absolute" zIndex={12} top={22} right={22}>
              <Icon
                button
                onClick={onClose}
                src={closeButtonIcon}
                id={closeButtonId || 'close-desktop-modal'}
                alt="close"
              />
            </Box>
          )}
          {title && (
            <Box position="absolute" top={22} left={22}>
              <Text variant="body/small" fontWeight="bold" {...titleProps}>
                {title}
              </Text>
            </Box>
          )}
          {children}
        </Box>
      </SheetWrapper>
    </StyledAside>
  )

  if (isMounted()) {
    if (open && !usingPortal) return Content
    if (portalOpen) return ReactDOM.createPortal(Content, document.body)
  }
  return null
}

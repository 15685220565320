import { getApiRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { useCallback } from 'react'
interface ShareContactButtonState {
  usersBrowserCanSharePage: boolean
  onClickShareButton: () => Promise<void>
}
export function useShareContactButtonState({
  linkId
}: {
  linkId: string
}): ShareContactButtonState {
  const navigatorShareMethodIsCallable =
    typeof navigator !== 'undefined' &&
    navigator &&
    typeof navigator.share !== 'undefined' &&
    navigator.share
  const usersBrowserCanSharePage = Boolean(navigatorShareMethodIsCallable)

  const onClickShareButton = useCallback(async () => {
    if (navigatorShareMethodIsCallable && navigator.share) {
      await navigator
        .share({
          url: `${getApiRootUrl()}/contact?linkId=${linkId}`
        })
        .catch(() => {
          //pass
          return
        })
    }
  }, [linkId, navigatorShareMethodIsCallable])

  return { usersBrowserCanSharePage, onClickShareButton }
}

import { LinkEditDrawerContent } from '../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.constants'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

export function useIsNewPreviewLink(): boolean {
  const { isNewPreviewLink, drawerContentState } = usePageSelector(
    (state: RootPageState) => state.linkEditReducer
  )
  return (
    isNewPreviewLink ||
    drawerContentState === LinkEditDrawerContent.ADD_WIDGET ||
    drawerContentState === LinkEditDrawerContent.ADD_LINK
  )
}

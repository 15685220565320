import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FP_MOBILE_EDITOR_STACKING_ORDER } from '../../../../../PagePreview/constants'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { PREVIEW_HEIGHT } from '../../../../../../../../../components/profile/DesktopProfile/components/FlowpagePreviewSection/constants'
import { WidgetType } from '../../../../../../../../../components/widgets/types/widgetType'
import { getWidgetComponent } from '../../../../../../../../../components/widgets/getWidgetComponent'
import { useGetPreviewLink } from '../../../../../../../../../hooks/useGetPreviewLink'
import { useIsNewPreviewLink } from '../../../../../../../../../hooks/useIsNewPreviewLink'
import styled from 'styled-components'

const PreviewModal = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${FP_MOBILE_EDITOR_STACKING_ORDER.FP_GATE_PREVIEW_MODAL_Z_INDEX};
`

export const EditLinkPreview = ({ isMobile }: { isMobile?: boolean }): JSX.Element | null => {
  const previewLink = useGetPreviewLink()
  const isNewPreviewLink = useIsNewPreviewLink()
  const isCRMGate =
    previewLink?.type === 'contactCollection' && previewLink?.displayType === 'popup'
  const isAgeGate = previewLink?.type === 'ageGate'
  const isContactInfoLink = previewLink?.type === 'contactInfo'

  const isGate = isCRMGate || isAgeGate

  // if it is not a new link and is not a gate, don't show preview component since link card will auto update
  if (!previewLink || (!isNewPreviewLink && !isGate)) return <></>

  // Do not preview contactInfo link on desktop because its preview is already shown
  // as <DesktopContactBar> on packages/page/src/components/flowpage/FlowPageContent/index.tsx
  if (isContactInfoLink && !isMobile) return null

  const Component = getWidgetComponent(
    previewLink.provider as LinkProvider,
    previewLink.type as WidgetType
  )

  if (isGate && Component) {
    return (
      <PreviewModal data-testid="gate-preview-modal" height={PREVIEW_HEIGHT}>
        <Box
          margin="0px 16px"
          borderRadius="12px"
          height="min-content"
          backgroundColor="secondary.backgroundDark"
        >
          <Component key={previewLink.id} link={previewLink} preview={true} isEditLinkPreview />
        </Box>
      </PreviewModal>
    )
  }

  return (
    Component && (
      <Component key={previewLink.id} link={previewLink} preview={true} isEditLinkPreview />
    )
  )
}

import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactBarProps } from './types'
import { Flowpage as ContactInfoWidget } from '../../widgets/ContactInfo/index'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'

import { FC } from 'react'

export const DesktopContactBar: FC<ContactBarProps> = (props: ContactBarProps) => {
  return (
    <>
      {props.contacts && (
        <Box flexDirection="column" width="100%">
          <ContactInfoWidget
            link={props.contacts as Omit<Link, 'userId'>}
            preview={props.preview}
          />
          <Spacer mb="24px" />
        </Box>
      )}
    </>
  )
}

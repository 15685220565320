import { Destination, WIDGETS } from './manifest'
import { FlowpageProps } from './types/FlowpageProps'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { WidgetType } from './types/widgetType'

export const getWidgetComponent = (
  provider: LinkProvider,
  widgetType: WidgetType
): React.ComponentType<FlowpageProps> | null => {
  if (provider === LinkProvider.WIDGET) {
    if (!Object.keys(WIDGETS).includes(widgetType)) {
      return null
    }
    return WIDGETS[widgetType].Flowpage
  } else {
    return Destination.Flowpage
  }
}

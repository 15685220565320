import { DesktopModal, FullPageModal, SheetModal } from '../../molecules'
import { FC, useEffect, useMemo } from 'react'
import { ModalProps, ModalVariants } from '../../../types'
import { SIZES } from '@dtx-company/true-common/src/constants/mediaQueries'
import { theme } from '../../../../src/theme'
import { useMediaQuery } from '../../../../src/utils'
import { usePreviousValue } from '@dtx-company/true-common/src/hooks/usePreviousValue/usePreviousValue'

const getModalComponent = (variant: ModalVariants): typeof SheetModal => {
  let Component
  switch (variant) {
    case ModalVariants.FULLPAGE:
      Component = FullPageModal
      break
    case ModalVariants.DESKTOP:
      Component = DesktopModal
      break
    case ModalVariants.SHEET:
    default:
      Component = SheetModal
  }
  return Component
}

export const Modal: FC<ModalProps> = ({
  children,
  variant,
  open,
  onClose,
  delay = 250,
  title,
  breakpoint = SIZES.desktop,
  usingPortal = true,
  hideCloseButton,
  ...rest
}: ModalProps) => {
  const wasOpen = usePreviousValue(open)
  useEffect(() => {
    if (document) {
      if (open) {
        document.body.style.overflow = 'hidden'
      } else if (wasOpen && !open) {
        document.body.style.overflow = ''
      }
      return function cleanup() {
        if (wasOpen) document.body.style.overflow = ''
      }
    }
  }, [open, wasOpen])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      if (e.keyCode === 27 && open) {
        !hideCloseButton && onClose()
      }
    }
    window.addEventListener('keydown', onKeyDown)
    return function cleanup() {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onClose, open, hideCloseButton])

  const mobile = useMediaQuery(breakpoint)

  const Component = useMemo(() => {
    const variantsList = !Array.isArray(variant) ? [variant] : variant
    if (!mobile && variantsList.length > 1) return getModalComponent(variantsList[1])
    return getModalComponent(variantsList[0])
  }, [mobile, variant])

  return (
    <Component
      title={title}
      delay={delay}
      onClose={onClose}
      open={open}
      data-testid="Modal"
      usingPortal={usingPortal}
      titleProps={{ fontFamily: theme.fontFamily }}
      zIndex={1300} //mui modal zIndex
      hideCloseButton={hideCloseButton}
      {...rest}
    >
      {children}
    </Component>
  )
}
